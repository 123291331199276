import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';

function Index() {
  return (
    <main>
      <Helmet>
        <title>Personify Media - Where Memes Are More Than Dreams</title>
      </Helmet>
      <h1>Personify Media</h1>
      <h2>Coming to a screen near you soon 😃</h2>
    </main>
  );
}

export default Index;
